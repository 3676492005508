body {
  margin: auto;
  background-color: #282c34;
  text-align: center;
  min-height: 100vh;
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.main {
  position: relative;
  width: 200px;
  height: 50px;
  background: #272727;
  cursor: pointer;
  border-radius: 5px;
  border: 2px solid white;
  overflow: hidden;
}

.fill {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

button{
  outline:none; 
  -webkit-tap-highlight-color: transparent;
}

input[type=text] {
  height: 2rem;
  padding: 2px 6px;
  margin: 8px 8px;
  display: inline-block;
  border: 1px solid #a3aab8;
  border-radius: 5px;
  box-sizing: border-box;
}

.item{
  font-size: 2rem;
  color: #6c778d;
}

.button{
  margin: auto 5px;
  height: 2rem;
  border: 1px solid #a3aab8;
  border-radius: 5px;
  box-sizing: border-box;
}